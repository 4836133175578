import React from "react";
import useTranslation from "hooks/useTranslation";

import { Link } from "gatsby";

export default function BlogPostPreview({ node }) {
  const { t } = useTranslation();
  return (
    <div>
      <h2>
        <Link to={node.fields.slug}>
          {node.frontmatter.title || node.fields.slug}
        </Link>
      </h2>
      <p
        className="text-caption"
        dangerouslySetInnerHTML={{
          __html: node.frontmatter.description || node.excerpt,
        }}
      />
      <p className="text-xs text-caption">
        {t("blog:meta", {
          author: "Wouter Raateland",
          date: node.frontmatter.date,
        })}
      </p>
    </div>
  );
}
