import React from "react";
import useTranslation from "hooks/useTranslation";

import PageLayout from "layouts/Page";

import SEO from "components/SEO";
import BlogPostPreview from "components/BlogPostPreview";

export default ({ data }) => {
  const { t } = useTranslation();
  const posts = data.allMarkdownRemark.edges;

  return (
    <PageLayout>
      <SEO title={t`navigation:blog`} />
      <div className="w-full max-w-2xl mx-auto px-4 space-y-8">
        <h1>{t`blog:title`}</h1>
        {posts.map(({ node }) => (
          <BlogPostPreview key={node.fields.slug} node={node} />
        ))}
        {posts.length === 0 && <p>{t`blog:noPosts`}</p>}
      </div>
    </PageLayout>
  );
};
